body {
  padding: 0;
  margin: 0;
}
.hidden {
  display: none;
}
header .RaAppBar-toolbar {
  padding-right: 5px !important;
}
.MuiInputLabel-root[for="recPages"] {
  left: -10px;
}
.RaBulkActionsToolbar-topToolbar {
  padding: 0px !important;
}
.welcome .MuiGrid-grid-xs-12 .MuiCardHeader-action {
  margin: 0 !important;
}
.welcome .MuiGrid-grid-xs-2 .MuiCardHeader-content {
  text-align: right;
}

.RaSimpleShowLayout-stack .ra-field,
.RaTabbedShowLayout-content .ra-field {
  margin: 8px 0;
}

.RaChipField-chip {
  width: fit-content;
}

.MuiDrawer-root a[href^='#/_smart_object_batch'],
.MuiDrawer-root a[href^='#/_manufacturing_tag'] {
  display: none;
}